
































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import { ApiResource } from '@/types';

export default Vue.extend({
  props: {
    businessVanity: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      detect: 'business/Detect',
      list: 'business/Pages/List',
    }),
    business(): ApiResource.Business {
      return this.detect(this.businessVanity);
    },
    pages(): ApiResource.Profile.Page[] {
      return this.list(this.business.id).sort(
        (a: ApiResource.Profile.Page, b: ApiResource.Profile.Page) => a.weight - b.weight,
      );
    },
  },
});
